import FacebookLogo from '@crehana/icons/FacebookLogo';
import GoogleIcon from '@crehana/icons/GoogleIcon';
import { Tooltip } from '@crehana/ui';
import { white } from '@crehana/ui/dist/styles/colors';

import LoadingOverlay from '../../LoadingOverlay';

type SocialButtonsVariantMinimalV2Props = {
  shouldRenderFacebookButton: boolean;
  facebookButtonProps: {
    onClick: () => void;
  };
  gmailButtonProps: {
    id: string;
    ref: React.RefObject<HTMLButtonElement>;
  };
  processingSocialAuth: boolean;
  loadingFacebookAndGoogleScripts: boolean;
  currentForm?: string;
  hideFBAuthButton?: boolean;
  hideFBAuthLink?: boolean;
  nextUrl?: string;
  facebookLabel: string;
  googleLabel: string;
};
const SocialButtonsVariantMinimalV2: React.FC<
  React.PropsWithChildren<SocialButtonsVariantMinimalV2Props>
> = ({
  shouldRenderFacebookButton,
  facebookButtonProps,
  gmailButtonProps,
  processingSocialAuth,
  loadingFacebookAndGoogleScripts,
  hideFBAuthButton,
  hideFBAuthLink,
  nextUrl,
  facebookLabel,
  googleLabel,
}) => {
  const renderFBAuth = () => {
    if (hideFBAuthButton && hideFBAuthLink) return null;
    if (hideFBAuthButton && !hideFBAuthLink) {
      return (
        <Tooltip label={facebookLabel} position="bottom" type="regular">
          <a
            className="flex items-center justify-center w-48 h-48 bg-white rounded-full"
            href={`https://www.crehana.com/login/facebook/?next=${nextUrl}`}
            {...facebookButtonProps}
          >
            <FacebookLogo size={28} color={white} />
          </a>
        </Tooltip>
      );
    }
    return (
      <Tooltip label={facebookLabel} position="bottom" type="regular">
        <button
          className="flex items-center justify-center w-48 h-48 bg-white rounded-full"
          type="button"
          {...facebookButtonProps}
        >
          <FacebookLogo size={28} />
        </button>
      </Tooltip>
    );
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-center m-auto max-w-280">
        {shouldRenderFacebookButton && renderFBAuth()}
        <Tooltip label={googleLabel} position="bottom" type="regular">
          <button
            className="flex items-center justify-center w-48 h-48 ml-40 bg-white rounded-full"
            type="button"
            style={{
              display: window.navigator.userAgent
                .toLowerCase()
                .includes('instagram')
                ? 'none !important'
                : 'flex',
            }}
            {...gmailButtonProps}
          >
            <GoogleIcon size={28} color={white} />
          </button>
        </Tooltip>
      </div>
      {(processingSocialAuth || loadingFacebookAndGoogleScripts) && (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default SocialButtonsVariantMinimalV2;
