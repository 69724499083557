import * as React from 'react';

import { useTranslation } from '@crehana/i18n';
import FacebookLogo from '@crehana/icons/FacebookLogo';
import GoogleIcon from '@crehana/icons/GoogleIcon';

import LoadingOverlay from '../../LoadingOverlay';

type SocialButtonsVariantMinimalProps = {
  shouldRenderFacebookButton: boolean;
  facebookButtonProps: {
    onClick: () => void;
  };
  gmailButtonProps: {
    id: string;
    ref: React.RefObject<HTMLButtonElement>;
  };
  processingSocialAuth: boolean;
  loadingFacebookAndGoogleScripts: boolean;
  currentForm?: string;
  hideFBAuthButton?: boolean;
  hideFBAuthLink?: boolean;
  nextUrl?: string;
};
const SocialButtonsVariantMinimal: React.FC<
  React.PropsWithChildren<SocialButtonsVariantMinimalProps>
> = ({
  shouldRenderFacebookButton,
  facebookButtonProps,
  gmailButtonProps,
  processingSocialAuth,
  loadingFacebookAndGoogleScripts,
  currentForm,
  hideFBAuthButton,
  hideFBAuthLink,
  nextUrl,
}) => {
  const { t } = useTranslation();
  const renderFBAuth = () => {
    if (hideFBAuthButton && hideFBAuthLink) return null;
    if (hideFBAuthButton && !hideFBAuthLink) {
      return (
        <a
          href={`https://www.crehana.com/login/facebook/?next=${nextUrl}`}
          {...facebookButtonProps}
        >
          <FacebookLogo size={26} />
        </a>
      );
    }
    return (
      <button type="button" {...facebookButtonProps}>
        <FacebookLogo size={26} />
      </button>
    );
  };

  const title =
    currentForm === 'REGISTER_VIEW'
      ? t('SOCIAL_BUTTON_TEXT')
      : t('SOCIAL_BUTTON_TEXT_LOGIN');

  return (
    <div className="w-full relative flex items-center justify-center">
      <span className="font-caption tw-font-semibold flex-shrink-0 inline-block mr-16">
        {title}
      </span>
      {shouldRenderFacebookButton && renderFBAuth()}
      <span className="font-caption tw-font-semibold flex-shrink-0 inline-block mx-12 h-20">
        {' o '}
      </span>
      <button
        type="button"
        style={{
          display: window.navigator.userAgent
            .toLowerCase()
            .includes('instagram')
            ? 'none !important'
            : 'flex',
        }}
        {...gmailButtonProps}
      >
        <GoogleIcon size={26} />
      </button>
      {(processingSocialAuth || loadingFacebookAndGoogleScripts) && (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default SocialButtonsVariantMinimal;
